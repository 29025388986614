/*
Template Name: Skote - Admin & Dashboard Template
Author: digiflute
Version: 4.0.0.
Website: https://digiflute.com/
Contact: info@digiflute.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";