/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
.simplebar-wrapper #side-menu{
  margin: 10px;
}
.simplebar-wrapper #side-menu .mm-active .active {
  color: #ffffff !important;
  background: #212121;
}

#sidebar-menu ul li a {
  color: #ffffff !important;
}
.navbar-header{
  box-shadow: 1px 4px 5px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 1px 4px 5px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 1px 4px 5px 0px rgb(0 0 0 / 20%);
}

.manage-venue-edit .accordion-body .square.thin{
  position: absolute;
  z-index: 2;
  width: -webkit-fill-available;
  margin-right: 15px;
  }
  .scrollbar-deep-purple::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
  }
  
  .scrollbar-deep-purple::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }
  
  .scrollbar-deep-purple::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #dda740;
  }
  
  .scrollbar-deep-purple {
    /*scrollbar-color: #512da8 #F5F5F5;*/
  }
  
  .bordered-deep-purple::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #002F7B;
  }
  
  .bordered-deep-purple::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
  }
  
  .square::-webkit-scrollbar-track {
    border-radius: 0 !important;
  }
  
  .square::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
  }
  
  .thin::-webkit-scrollbar {
    width: 5px;
  }
  
  .csfft_scroll {
    padding-left: 5px;
    width: 100%;
  }
  
  .csfft_scroll {
    overflow-y: auto; /* Use auto to show scrollbar only if needed */
    max-height: 143px; /* Limit the height to 200px */
  }
  
  @media (max-height: 200px) {
    .csfft_scroll {
        overflow-y: visible; /* Remove scrollbar if height less than 200px */
        max-height: none; /* Remove max-height */
    }
  }



  .carousel-item-containers {
    display: flex;
  }
  
  .carousel-items {
    flex: 1;
    padding: 0 5px; /* Adjust as needed for spacing */
  }

  .arrow-btn .btn, .arrow-btn .btn:focus, .arrow-btn .btn:hover{
    border: none;
  }




